import React from "react";
import "./src/styles/global.css";
import { LazyMotion, domAnimation } from "framer-motion";

export const onRouteUpdate = () => {
  if (typeof window !== `undefined`) {
    window.scrollTo(0, 0);
  }
};

export const shouldUpdateScroll = (args) => {
  return false;
};

export function wrapPageElement({ element }) {
  return <LazyMotion features={domAnimation}>{element}</LazyMotion>;
}
