exports.components = {
  "component---src-pages-b-2-b-jsx": () => import("./../../../src/pages/b2b.jsx" /* webpackChunkName: "component---src-pages-b-2-b-jsx" */),
  "component---src-pages-historie-jsx": () => import("./../../../src/pages/historie.jsx" /* webpackChunkName: "component---src-pages-historie-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-ismejeri-jsx": () => import("./../../../src/pages/ismejeri.jsx" /* webpackChunkName: "component---src-pages-ismejeri-jsx" */),
  "component---src-pages-klima-ansvar-jsx": () => import("./../../../src/pages/klima-ansvar.jsx" /* webpackChunkName: "component---src-pages-klima-ansvar-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-okologi-jsx": () => import("./../../../src/pages/okologi.jsx" /* webpackChunkName: "component---src-pages-okologi-jsx" */),
  "component---src-pages-ravarer-jsx": () => import("./../../../src/pages/ravarer.jsx" /* webpackChunkName: "component---src-pages-ravarer-jsx" */),
  "component---src-pages-vores-is-jsx": () => import("./../../../src/pages/vores-is.jsx" /* webpackChunkName: "component---src-pages-vores-is-jsx" */),
  "component---src-pages-webbutik-jsx": () => import("./../../../src/pages/webbutik.jsx" /* webpackChunkName: "component---src-pages-webbutik-jsx" */),
  "component---src-template-materials-jsx": () => import("./../../../src/template/materials.jsx" /* webpackChunkName: "component---src-template-materials-jsx" */),
  "component---src-template-product-jsx": () => import("./../../../src/template/product.jsx" /* webpackChunkName: "component---src-template-product-jsx" */),
  "component---src-template-webbutik-jsx": () => import("./../../../src/template/webbutik.jsx" /* webpackChunkName: "component---src-template-webbutik-jsx" */)
}

